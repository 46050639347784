<template>
  <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
    <v-text-field
      :label="$t('name')"
      :rules="[required]"
      v-model="company.Name"
    />
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="company.CNPJ"
          placeholder="00.000.000/0000-00"
          v-mask="['##.###.###/####-##']"
          :label="$t('company_id')"
          :rules="[(v) => (!!v && v.length === 18) || required()]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field :label="$t('phone')" v-model="company.Phone" />
      </v-col>
    </v-row>
    <v-text-field :label="$t('address')" v-model="company.Address" />

    <v-text-field
      :label="$t('email')"
      :rules="[email_valid]"
      v-model="company.Email"
    />

    <v-text-field :label="$t('site')" v-model="company.Website" />

    <div>
      <div class="mt-2">
        <span>{{ $t("report_by_powerBI") }}</span>
        <v-radio-group center v-model="reportByPowerBI" row>
          <v-radio :label="$t('yes')" :value="true"></v-radio>
          <v-radio :label="$t('no')" :value="false"></v-radio>
        </v-radio-group>
      </div>
      <v-alert
        v-if="!company.Pbi && reportByPowerBI && company.PowerBiReportId"
        dense
        outlined
        type="error"
        style="padding-top: 8px; padding-bottom: 8px; padding-left: 4px"
      >
        {{ $t("pbi_invalid_id_text") }}
      </v-alert>
      <v-text-field
        class="mt-0 pt-2 mb-3"
        :label="$t('powerBI_key')"
        v-if="reportByPowerBI"
        v-model="keyPowerBI"
        :rules="[required]"
      />
    </div>

    <!-- <v-text-field
      :label="$t('company_presentation')"
      :hint="$t('slideshare_hint')"
      v-model="company.PresentationId"
    /> -->
    <v-expansion-panels>
      <v-expansion-panel class="mt-1">
        <v-expansion-panel-header>
          {{ $t("company_info") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            :label="$t('revenue')"
            v-model="company.Revenue"
            v-money="money"
          />
          <v-text-field
            :label="$t('reserved_quotas')"
            type="number"
            suffix="%"
            v-model="company.ReservedQuotas"
            @change="
              company.ReservedQuotas = parseFloat(company.ReservedQuotas)
            "
          />
          <v-text-field
            :label="$t('fundraising_value')"
            v-model="company.FundraisingValue"
            v-money="money"
          />
          <div v-if="!partnersLoading">
            <p class="mb-0" style="font-size: 11px">
              {{ $t("min_allocation_value") }}
            </p>
            <v-row align="center">
              <v-col cols="6" md="6" lg="8">
                <v-select
                  :items="partners"
                  item-text="name"
                  hide-details
                  dense
                  :label="$tc('partner', 1)"
                  v-model="partnerSelected"
                  return-object
                  outlined
                  @change="handlePartnerMinValue"
                ></v-select>
              </v-col>
              <v-col cols="6" md="6" lg="2" class="mb-2">
                <v-text-field
                  :label="
                    $t('min_value_required_currency', { currency: '(R$)' })
                  "
                  type="number"
                  prefix="R$"
                  hide-details
                  v-model="minAllocationValueBRL"
                  @change="(e) => handleMinValue(e, CurrencyEnum.BRL)"
                />
              </v-col>
              <v-col cols="6" md="6" lg="2" class="mb-2">
                <v-text-field
                  :label="
                    $t('min_value_required_currency', { currency: '(US$)' })
                  "
                  type="number"
                  prefix="US$"
                  hide-details
                  v-model="minAllocationValueUS"
                  @change="(e) => handleMinValue(e, CurrencyEnum.USD)"
                />
              </v-col>
            </v-row>
          </div>
          <v-text-field
            :label="$t('profitability')"
            v-model="company.Profitability"
            v-money="money"
          />
          <p>{{ $t("esg") }}:</p>
          <v-row class="mx-12">
            <span class="mt-1">0</span>
            <v-slider
              class="mx-2"
              min="0"
              max="5"
              track-color="#383838"
              thumb-color="primary"
              :thumb-label="true"
              v-model="company.ESG"
              @change="company.ESG = parseFloat(company.ESG)"
            ></v-slider>
            <span class="mt-1">5</span>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-1">
        <v-expansion-panel-header>
          {{ $t("investments_docs") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-select
            solo
            outlined
            :items="StockOptions"
            :label="$t('stock_type')"
            item-text="Name"
            item-value="Id"
            :disabled="!StockOptions || StockOptions.length == 0"
            v-model="company.StockTypeId"
          >
          </v-select>
          <v-text-field
            :label="$tc('adhesion_term', 1)"
            v-model="company.AdhesionContractClickSignlKey[$i18n.locale]"
          />
          <v-text-field
            :label="$t('newsletter_subscription')"
            v-model="company.NewsletterSubscriptionClickSignlKey[$i18n.locale]"
          />
          <v-text-field
            :label="$t('commitment_term_anvil')"
            v-model="company.CommitmentTermAnvilUrl[$i18n.locale]"
          />
          <v-text-field
            :label="$t('investment_proxy_pj_anvil')"
            v-model="company.InvestmentProxyPJAnvilUrl[$i18n.locale]"
          />
          <v-text-field
            :label="$t('investment_proxy_pf_anvil')"
            v-model="company.InvestmentProxyPFAnvilUrl[$i18n.locale]"
          />
          <span style="font-size: 14px; font-weight: bold">{{
            $t("commitment_term_text")
          }}</span>
          <quill-editor
            class="my-4"
            ref="myQuillEditor"
            v-model="company.CommitmentTermText[$i18n.locale]"
          />
          <span style="font-size: 14px; font-weight: bold">{{
            $t("investment_proxy_pj_text")
          }}</span>
          <quill-editor
            class="my-4"
            ref="myQuillEditor"
            v-model="company.InvestmentProxyPJText[$i18n.locale]"
          />
          <span style="font-size: 14px; font-weight: bold">{{
            $t("investment_proxy_pf_text")
          }}</span>
          <quill-editor
            class="my-4"
            ref="myQuillEditor"
            v-model="company.InvestmentProxyPFText[$i18n.locale]"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-1">
        <v-expansion-panel-header>
          {{ $t("investment_vehicle") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-combobox
            :label="$t('select_vehicles')"
            v-model="vehiclesSelected"
            :items="vehiclesOptions"
            item-text="name"
            item-value="id"
            multiple
            solo
            outlined
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-1">
        <v-expansion-panel-header>
          {{ $t("video_options") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="mt-2">
            <span>{{ $t("is_details_playlist") }}</span>
            <v-radio-group center v-model="company.IsDetailsPlaylist" row>
              <v-radio :label="$t('playlist')" :value="true"></v-radio>
              <v-radio :label="$tc('video', 1)" :value="false"></v-radio>
            </v-radio-group>
          </div>

          <v-text-field
            class="mt-0 pt-0 mb-3"
            :label="$t('details_video')"
            :hint="$tc('vimeo_hint', 2)"
            v-model="company.DetailsVideo[$i18n.locale]"
          />
        </v-expansion-panel-content> </v-expansion-panel
      ><v-expansion-panel class="mt-1">
        <v-expansion-panel-header>
          {{ $t("overall") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><v-select
            solo
            outlined
            :items="categories"
            :label="$tc('category', 1)"
            item-value="id"
            v-model="company.CategoryId"
          >
            <template v-slot:selection="{ item }">
              {{ translateCategoryOption(item) }}
            </template>
            <template v-slot:item="{ item }">
              {{ translateCategoryOption(item) }}
            </template>
          </v-select>

          <div class="mt-2">
            <span>{{ $t("is_showcased") }}</span>
            <v-radio-group center v-model="company.Showcased" row>
              <v-radio :label="$t('yes')" :value="true"></v-radio>
              <v-radio :label="$t('no')" :value="false"></v-radio>
            </v-radio-group>
          </div>

          <div v-if="company.Showcased">
            <span>{{ $t("pipeline_category") }}</span>
            <v-radio-group
              center
              v-model="company.PipelineCategory"
              @change="pipeline_category_changed"
              row
            >
              <v-radio :label="$t('ongoing_opts')" :value="0"></v-radio>
              <v-radio :label="$t('portfolio_companies')" :value="1"></v-radio>
              <v-radio
                v-if="roundsEnv === 'FALSE'"
                :label="$t('exclusive_opportunities')"
                :value="2"
              ></v-radio>
            </v-radio-group>

            <v-select
              v-if="pipelineIndexOptions.length > 0"
              :items="pipelineIndexOptions[company.PipelineCategory]"
              :label="$t('index')"
              v-model="company.PipelineIndex"
              outlined
            ></v-select>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-1">
        <v-expansion-panel-header>
          {{ $t("photo_options") }}
        </v-expansion-panel-header>
        <v-expansion-panel-content
          ><span>{{ $t("banner") }}</span>
          <div class="mt-1">
            <v-row align="center">
              <v-img
                class="ml-3"
                v-if="company.Base64Banner != null"
                :src="company.Base64Banner"
                max-height="5rem"
                max-width="5rem"
              >
              </v-img>
              <v-img
                class="ml-3"
                v-else-if="company.Banner != null"
                :src="show_photo(company.Banner)"
                max-height="5rem"
                max-width="5rem"
              >
              </v-img>
              <input
                class="ml-4"
                id="imageBannerInput"
                name="imageBannerInput"
                ref="imageBannerInput"
                type="file"
                accept="image/png, image/jpeg, image/bmp"
                @change="setImageBanner"
              />
            </v-row>
          </div>
          <div class="mt-2 mb-2">
            <span>{{ $t("logo") }}</span>
            <div class="mt-1">
              <v-row align="center">
                <v-img
                  class="ml-3"
                  v-if="company.Base64Logo != null"
                  :src="company.Base64Logo"
                  max-height="5rem"
                  max-width="5rem"
                >
                </v-img>
                <v-img
                  class="ml-3"
                  v-else-if="company.Logo != null"
                  :src="show_photo(company.Logo)"
                  max-height="5rem"
                  max-width="5rem"
                >
                </v-img>
                <input
                  class="ml-4"
                  id="imageLogoInput"
                  name="imageLogoInput"
                  ref="imageLogoInput"
                  type="file"
                  accept="image/png, image/jpeg, image/bmp"
                  @change="setImageLogo"
                />
              </v-row>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card-actions class="pl-0 dxa_modal_actions">
      <v-btn
        class="dxa_modal_btnSuccess"
        color="primary"
        min-width="100"
        :loading="loading"
        type="submit"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        class="dxa_modal_btnError"
        color="secondary"
        min-width="100"
        @click="$router.go(-1)"
        >{{ $t("go_back") }}</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import { VMoney } from "v-money";
import { quillEditor } from "vue-quill-editor";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

import {
  formatToDecimalNumber,
  convertIntNumberInDecimal,
} from "@/shared/helpers/inputHelpers.js";

export default {
  name: "ManageCompanyBasic",
  components: {
    quillEditor,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationService(),
    loading: false,
    company_id: null,
    edit: false,
    CurrencyEnum,
    categories: [],
    partners: [],
    pipelineIndexOptions: [],
    partnersLoading: false,
    partnerSelected: null,
    reportByPowerBI: false,
    keyPowerBI: "",
    company: {
      Name: null,
      CNPJ: null,
      Address: null,
      Phone: null,
      Email: null,
      Website: null,
      Banner: null,
      Logo: null,
      CategoryId: null,
      DetailsVideo: {},
      CommitmentTermAnvilUrl: {},
      InvestmentProxyPFAnvilUrl: {},
      InvestmentProxyPJAnvilUrl: {},
      CommitmentTermText: {},
      InvestmentProxyPFText: {},
      InvestmentProxyPJText: {},
      AdhesionContractClickSignlKey: {},
      NewsletterSubscriptionClickSignlKey: {},
      IsDetailsPlaylist: false,
      Showcased: false,
      CompanyInvestmentVehicles: [],
      currencyOptions: [
        {
          text: "R$",
          value: CurrencyEnum.BRL,
        },
        {
          text: "US$",
          value: CurrencyEnum.USD,
        },
      ],
      CompanyAllocationMinValues: [],
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$",
      precision: 2,
      masked: false,
    },
    directives: { money: VMoney },
    vehiclesOptions: [],
    vehiclesSelected: null,
    roundsEnv: process.env.VUE_APP_ROUNDS,
    minAllocationValueUS: null,
    minAllocationValueBRL: null,
  }),

  computed: {
    email_valid() {
      return (v) =>
        !v ||
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          v
        ) ||
        this.$t("format_invalid");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    company_prop: Object,
    OriginalIndex: Number,
    OriginalCategory: Number,
    StockOptions: Array,
  },

  async created() {
    this.get_partners();
    this.get_pipeline_index_options();
    this.get_categories();
    this.getAllVehicles();
    this.alert_msg = null;
    if (this.company_prop != null) {
      this.edit = true;
      this.company = this.company_prop;

      if (this.company.PowerBiReportId) {
        this.keyPowerBI = this.company.PowerBiReportId;
        this.reportByPowerBI = true;
      } else {
        this.keyPowerBI = null;
        this.reportByPowerBI = false;
      }

      this.getMinValuesNoPartner();
      this.initialize_translatable_field("DetailsVideo");
      this.initialize_translatable_field("CommitmentTermAnvilUrl");
      this.initialize_translatable_field("InvestmentProxyPFAnvilUrl");
      this.initialize_translatable_field("InvestmentProxyPJAnvilUrl");
      this.initialize_translatable_field("CommitmentTermText");
      this.initialize_translatable_field("InvestmentProxyPFText");
      this.initialize_translatable_field("InvestmentProxyPJText");
      this.initialize_translatable_field("AdhesionContractClickSignlKey");
      this.initialize_translatable_field("NewsletterSubscriptionClickSignlKey");
    } else {
      this.edit = false;
    }

    if (this.company.FundraisingValue) {
      this.company.FundraisingValue = convertIntNumberInDecimal(
        this.company.FundraisingValue
      );
    }

    if (this.company.Revenue) {
      this.company.Revenue = convertIntNumberInDecimal(this.company.Revenue);
    }

    if (this.company.Profitability) {
      this.company.Profitability = convertIntNumberInDecimal(
        this.company.Profitability
      );
    }

    if (this.company.CompanyInvestmentVehicles.length > 0) {
      const companyInvestmentVehicles =
        this.company.CompanyInvestmentVehicles.map((vehicle) => {
          return {
            id: vehicle.InvestmentVehicle.Id,
            name: vehicle.InvestmentVehicle.Name,
          };
        });

      this.vehiclesSelected = companyInvestmentVehicles;
    }
  },

  methods: {
    getMinValuesNoPartner() {
      if (this.company.CompanyAllocationMinValues.length) {
        const minValuesNoPartner =
          this.company.CompanyAllocationMinValues.filter((item) => {
            return item.PartnerB2bId === null;
          });

        if (minValuesNoPartner.length) {
          const minValueBRL = minValuesNoPartner.find(
            (item) => item.Currency === this.CurrencyEnum.BRL
          );

          const minValueUSD = minValuesNoPartner.find(
            (item) => item.Currency === this.CurrencyEnum.USD
          );

          if (minValueBRL) this.minAllocationValueBRL = minValueBRL.MinValue;
          if (minValueUSD) this.minAllocationValueUS = minValueUSD.MinValue;
        }
      }
    },
    handlePartnerMinValue() {
      if (this.company.CompanyAllocationMinValues.length) {
        // tratamento para quando for partner (no-partner)
        // id do parceiro (no-partner) no front = "0" no back = null

        if (this.partnerSelected.id === null) {
          this.getMinValuesNoPartner();
        } else {
          const partnerMinValues =
            this.company.CompanyAllocationMinValues.filter(
              (item) => item.PartnerB2bId === this.partnerSelected.id
            );

          const partnerMinValueBRL = partnerMinValues.find(
            (item) => item.Currency === this.CurrencyEnum.BRL
          );

          const partnerMinValueUSD = partnerMinValues.find(
            (item) => item.Currency === this.CurrencyEnum.USD
          );

          if (partnerMinValueBRL) {
            this.minAllocationValueBRL = partnerMinValueBRL.MinValue;
          } else {
            this.minAllocationValueBRL = null;
          }

          if (partnerMinValueUSD) {
            this.minAllocationValueUS = partnerMinValueUSD.MinValue;
          } else {
            this.minAllocationValueUS = null;
          }
        }
      }
    },
    remove_investment_vehicle(index) {
      this.company.InvestmentVehicles.splice(index, 1);
    },
    add_investment_vehicle() {
      this.company.InvestmentVehicles.push({
        Id: null,
        Name: null,
        Nire: null,
        Cnpj: null,
        CompanyId: this.company.CompanyId,
      });
    },
    pipeline_category_changed() {
      if (this.edit && this.company.PipelineCategory == this.OriginalCategory) {
        this.company.PipelineIndex = this.OriginalIndex;
      } else {
        this.company.PipelineIndex =
          this.pipelineIndexOptions[this.company.PipelineCategory][
            this.pipelineIndexOptions[this.company.PipelineCategory].length - 1
          ];
      }
    },
    async get_pipeline_index_options() {
      this.apiService
        .getRequest("company/pipeline-category-index-options")
        .then((resp) => {
          this.pipelineIndexOptions = JSON.parse(resp.message);
          for (var i = 0; i < this.pipelineIndexOptions.length; i++) {
            if (this.company.PipelineCategory != i) {
              this.pipelineIndexOptions[i].push(
                this.pipelineIndexOptions[i].length + 1
              );
            }
          }
        })
        .catch((error) => {});
    },
    async getPartnerB2b() {
      await this.apiService
        .getRequest(`partnerb2b`)
        .then((resp) => {
          this.partners = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
    },
    async get_partners() {
      this.partnersLoading = true;
      await this.getPartnerB2b();
      this.partners.push({ name: "Geral", id: null });
      this.partnerSelected = this.partners[this.partners.length - 1];
      this.partnersLoading = false;
    },
    initialize_translatable_field(field) {
      if (this.company_prop[field] != null) {
        if (this.isJson(this.company_prop[field])) {
          this.company[field] = JSON.parse(this.company_prop[field]);
        } else {
          var text = this.company_prop[field];
          this.company[field] = {};
          this.company[field][this.$i18n.locale] = text;
        }
      } else {
        this.company[field] = {};
        this.company[field][this.$i18n.locale] = "";
      }
    },
    show_photo(path) {
      return path;
    },
    checkBox_changed(s, list) {
      if (list.includes(s)) {
        var index = list.indexOf(s);
        list.splice(index, 1);
      } else {
        list.push(s);
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    setImageBanner(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.company.Base64Banner = reader.result;
      };
    },
    setImageLogo(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.company.Base64Logo = reader.result;
      };
    },
    get_categories() {
      this.apiService
        .getRequest("category/list-all")
        .then((result) => {
          this.categories = result.content.filter(
            (x) => x.shownRegister === true
          );
        })
        .catch((error) => {});
    },
    email_error_msg: function () {
      return this.$t("format_invalid");
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.submit();
      } else {
        this.loading = false;
      }
    },
    handleMinValue(minValue, currency) {
      // verifica se exite um mínimo valor de investimento para o parceiro se existir vola o index do item
      // se não existir volta -1
      let partnerMinValueIndex =
        this.company.CompanyAllocationMinValues.findIndex(
          (item) =>
            item.PartnerB2bId === this.partnerSelected.id &&
            item.Currency === currency
        );

      // index > que -1 significa que tem valor mínimo para o parceiro então basta somente editar
      // se não existir cai no else, que cria um novo objeto na array
      if (partnerMinValueIndex !== -1) {
        this.company.CompanyAllocationMinValues[partnerMinValueIndex].MinValue =
          Number(minValue);
      } else {
        const partnerMinValueObject = {
          PartnerB2bId: this.partnerSelected.id,
          Currency: currency,
          MinValue: Number(minValue),
        };

        this.company.CompanyAllocationMinValues.push(partnerMinValueObject);
      }
    },
    submit: async function () {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.error = null;
        if (this.company.Email == "") {
          this.company.Email = null;
        }

        if (this.vehiclesSelected) {
          this.company.CompanyInvestmentVehicles = this.setVehiclesSelected(
            this.vehiclesSelected,
            this.company.CompanyId
          );
        }

        let obj = JSON.parse(JSON.stringify(this.company));

        if (obj.FundraisingValue) {
          obj.FundraisingValue = formatToDecimalNumber(obj.FundraisingValue);
        }

        if (obj.Revenue) {
          obj.Revenue = formatToDecimalNumber(obj.Revenue);
        }

        if (obj.Profitability) {
          obj.Profitability = formatToDecimalNumber(obj.Profitability);
        }

        obj.CNPJ = obj.CNPJ.replace(/\./g, "")
          .replace(/-/g, "")
          .replace("/", "");
        obj.DetailsVideo = JSON.stringify(obj.DetailsVideo);
        obj.CommitmentTermAnvilUrl = JSON.stringify(obj.CommitmentTermAnvilUrl);
        obj.InvestmentProxyPFAnvilUrl = JSON.stringify(
          obj.InvestmentProxyPFAnvilUrl
        );
        obj.InvestmentProxyPJAnvilUrl = JSON.stringify(
          obj.InvestmentProxyPJAnvilUrl
        );
        obj.CommitmentTermText = JSON.stringify(obj.CommitmentTermText);
        obj.InvestmentProxyPFText = JSON.stringify(obj.InvestmentProxyPFText);
        obj.InvestmentProxyPJText = JSON.stringify(obj.InvestmentProxyPJText);
        // obj.MinAllocationValue = JSON.stringify(obj.MinAllocationValue);
        obj.NewsletterSubscriptionClickSignlKey = JSON.stringify(
          obj.NewsletterSubscriptionClickSignlKey
        );
        obj.AdhesionContractClickSignlKey = JSON.stringify(
          obj.AdhesionContractClickSignlKey
        );
        obj.CompanyAllocationMinValues = [
          ...this.company.CompanyAllocationMinValues,
        ];

        if (this.reportByPowerBI && this.keyPowerBI) {
          obj.PowerBiReportId = this.keyPowerBI;
        } else {
          obj.PowerBiReportId = null;
        }

        if (this.edit) {
          this.apiService
            .putRequest("company/edit", obj)
            .then((result) => {
              this.loading = false;
              this.$emit("alert", true, this.$t("saved"));
              this.$emit("saved");
            })
            .catch((error) => {
              this.$emit("alert", false, error.body.message);
              this.loading = false;
            });
        } else {
          await this.apiService
            .postRequest("company/add", obj)
            .then((result) => {
              this.$router.go(-1);
            })
            .catch((error) => {
              this.$emit("alert", false, error.body.message);
              this.error = error.body.message;
            });
          this.loading = false;
        }
      } catch (error) {}
    },
    getAllVehicles() {
      this.apiService
        .getRequest("investmentvehicle")
        .then((result) => {
          this.vehiclesOptions = result.content;
        })
        .catch((error) => {
          return error;
        });
    },
    setVehiclesSelected(vehicles, companyId) {
      const setVehicles = vehicles.map((vehicle) => {
        return {
          CompanyId: companyId,
          InvestmentVehicleId: vehicle.id,
        };
      });

      return setVehicles;
    },

    translateCategoryOption(item) {
      return this.$t(item.key);
    },
  },
};
</script>
